.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #a4004f;
  min-height: 4vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.screen{
  position: absolute;
  width: 100%;
}

.slotpicker{
  position: absolute;
  top: 70px;
  border-radius: 0 0 4px 4px;
  background: #FFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  width: 540px;
  display: none;
}
.dwa{
  left: 0px;
}

.input-text{
  width: 450px;
  height: 17px;
  border-radius: 20px;
  background: white;
  font-size: 14px;
  padding: 8px 16px;
  line-height: 1.71;
  color: #666666;
}

.header_delivery-inner{
  color: #FFF;
}
.App-link {
  color: #61dafb;
}

form{
  margin: 20px;
}

label{
  color: #000;
}

input{
  color: #000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Box{
  margin: 20px;
}

.config{
  position: absolute;
  bottom: 10px;
  left: 10px;
  height: 500px;
  width: 400px;
  overflow: scroll;
  border: 1px solid #ccc;
  padding: 20px;
}

pre{
  height: 300px;
  overflow-x: scroll;
  width: 100%;
}

.customjwt{
  width: 900px;
  height: auto;
}

.json{
  max-height: 300px;
  overflow-y: scroll;
  padding: 20px;
  border: 1px solid #ccc;
}